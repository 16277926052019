@use "node_modules/@ngneat/hot-toast/src/styles/styles.scss";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/font-awesome/css/font-awesome.css";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@font-face {
  font-family: "Atyp";
  src: local("Atyp Medium"), local("Atyp-Medium"),
    url("assets/fonts/AtypDisplay-Medium.woff2") format("woff2"),
    url("assets/fonts/AtypDisplay-Medium.woff") format("woff"),
    url("assets/fonts/AtypDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "U8";
  src: url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.eot");
  src: url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/7017698b5c5a2cc397cd3b0c0984d76f.svg#U8")
      format("svg");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 2px;
    background: transparent;
    height: 2px;
    scroll-snap-type: x proximity;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

body {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  overflow-x: hidden;
}

:root {
  --primary-color: #df678c;
  --secondary-color: #3d1560;
  --warning-color: #eaac30;
  --bg-warning: #fff4eb;
  --text-warning-2: #ff9943;
  --danger-color: #e61b00;
  --bg-secondary: #e8e9ed;
  --secondary-hover: #4a1c7a;
  --secondary-light: #edd9ff;
  --text-secondary: #6d26ab;
  --text-success: #18bb0c;
  --muted: #797b89;
  --header: #1a202c;
  --bg-light: #f8f8fa;
  --bg-purple: #9b53d9;
  --content-color: #383a47;
  --white-color: #fff;
}

/* Dark mode */
// @media (prefers-color-scheme: dark) {
//   :root {
//     --bg-light: #202022;
//   }
//   body {
//     background: #121212;
//     color: #fff;
//   }

//   .card {
//     background: #121212;
//     color: #fff;
//     border-color: #2d2d2f;
//   }

//   .bg-white {
//     background: #121212 !important;
//     color: #fff;
//   }

//   .border-bottom {
//     border-bottom: 1px solid #2d2d2f !important;
//   }

//   .border-end {
//     border-right: 1px solid #2d2d2f !important;
//   }

//   .card-img-top {
//     filter: invert(1) hue-rotate(180deg);
//   }

//   thead {
//     background: #121212 !important;
//   }

//   td {
//     color: #cfd5e5 !important;
//   }

//   .table > :not(:last-child) > :last-child > * {
//     border-bottom-color: #2d2d2f !important;
//     border-top-color: #2d2d2f !important;
//     border-top: 1px solid #2d2d2f !important;
//   }

//   .search {
//     background: #121212 !important;
//     border: 1px solid #2d2d2f !important;
//   }

//   .date-dropdown {
//     img {
//       filter: invert(1);
//     }
//   }
// }

h1 {
  font-family: "U8";
  font-size: 2.125rem;
  font-weight: 700;
  color: var(--header);
}

input,
button,
textarea,
a {
  outline: none;
  border: none;
}

a,
button {
  cursor: pointer;
  transition: all 0.2s ease;
}

a:hover {
  opacity: 0.8;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-success {
  color: var(--text-success) !important;
}

.text-warning {
  color: var(--warning-color) !important;
}

.text-content {
  color: var(--content-color) !important;
}

.text-muted {
  color: var(--muted) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-secondary {
  background-color: var(--bg-secondary) !important;
}

.bg-danger {
  background-color: var(--danger-color) !important;
}

.bg-warning {
  background-color: var(--bg-warning) !important;
}

.text-warning-2 {
  color: var(--text-warning-2) !important;
}

.pointer {
  cursor: pointer;
}

//font weight
.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700 !important;
}

//font size
.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

// For Otp Input Component
.each_input:focus {
  border: 2px solid var(--primary-color) !important;
}

.nav-link {
  svg {
    path {
      transition: all 0.2s ease;
    }
  }

  &.active,
  &:hover {
    svg {
      path {
        fill: white;
      }
    }
  }

  &.settings:hover,
  &.settings.active {
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ngx-pagination {
  li {
    transition: all 0.2s ease;
    border: 1px solid transparent;
    border-radius: 8px !important;
    margin-inline: 0.5em;

    a {
      padding: 0.5rem 0.8rem !important;
      &:hover {
        background: none;
        color: var(--primary-color) !important;
      }
    }

    &:hover {
      border: 1px solid var(--primary-color);
    }

    &.current {
      padding: 8px 15px !important;
      border: 1px solid var(--primary-color);
      background: none !important;
      color: var(--primary-color) !important;
    }
  }
}

.NgxEditor__Wrapper {
  border-radius: 8px !important;
  overflow: hidden !important;
  border-color: #e78da9 !important;

  .NgxEditor__MenuBar {
    background-color: #1b1c20 !important;
    color: white;

    .NgxEditor__MenuItem:hover {
      background-color: #545454 !important;
    }

    .NgxEditor__Dropdown:hover {
      background-color: #545454 !important;
    }

    .NgxEditor__Seperator {
      border-left: none !important;
    }
  }

  .NgxEditor {
    min-height: 13em !important;
  }
}

.toolbar {
  background: #e8e9ed;
  border-radius: 0.5rem;
  padding: 0rem 0.5rem;

  .NgxEditor__MenuBar {
    background-color: transparent;
    color: #797b89;
  }
}

.form-control {
  .NgxEditor {
    min-height: 4.5em !important;
    border: none;

    .NgxEditor__Content {
      height: 100%;
    }
  }
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #3d1560 !important;
  background: #3d1560 !important;
}

.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid #c6c8d3;
  box-sizing: border-box;
  border-radius: 15px !important;
  padding: 1rem !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.btn {
  border-radius: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: none;
  outline: none;
  padding: 0.5rem 1.5rem;
}

.btn-secondary {
  background: var(--secondary-color);
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  border-radius: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    background: var(--secondary-hover);
  }

  &:active {
    background-color: var(--secondary-color) !important;
    opacity: 0.8;
  }
}

.btn-outline {
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-weight: 500;

  &:hover {
    background: #f8f8fa;
    border: 1px solid #e2e8f0;
  }
}

.chat-bubble * {
  margin-bottom: 0 !important;
}

.documentExpInput {
  &::after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    right: 25px;
    bottom: 4rem;
  }
}
